<template>
  <div class="contact-page">
    <vuestic-widget :loading="installFetching">
      <template slot="header">
        <div class="w-100">
          <div class="header-row d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <span class="title mb-0 mr-1">Inbox: </span>
              <multiselect
                class="install-select d-inline-block"
                style="min-width: 400px;"
                v-model="selectedInstall"
                :options="optionInstalls"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder=""
                label="label"
                track-by="label"
                :preselect-first="true"
                :show-labels="false"
                :allowEmpty="false"
                @input="onChangeInstall"
              ></multiselect>
            </div>
          </div>
        </div>
      </template>
      <div class="table-wrapper">
        <div class="filter-wrapper pb-2 mb-3">
          <div class="d-flex align-items-center">
            <AppDropdownSelect
              :options="bulkActionOptions"
              :autoHide=true
              :showArrow=false
              :selectable=false
              placement="bottom"
              popoverClass="more-custom-popover"
              @input="onBulkActionClick"
            >
              <template slot="trigger">
                <button
                  href="javascript:;"
                  class="btn btn-sm ml-2"
                  :class="{
                    'btn-outline text-primary': !contactSelectedCount,
                    'btn-primary': contactSelectedCount
                  }"
                >
                  <span>Bulk Actions</span>
                </button>
              </template>
            </AppDropdownSelect>
            <span class="ml-2" v-if="contactSelectedCount"><b>{{ contactSelectedCount }}</b> Selected</span>
          </div>
          <div>
            <div class="ml-2">
              <button
                class="btn btn-sm btn-primary mr-2" @click="onClickImportContact"
              >
                <span><i class="fa fa-upload"></i> Import</span>
              </button>
              <button
                class="btn btn-sm btn-primary mr-2" @click="onClickExport"
              >
                <span><i class="fa fa-download"></i> Export</span>
              </button>
              <router-link
                class="btn btn-sm btn-primary"
                :to="{ name: 'business.contacts.create', query: { install: selectedInstall ? selectedInstall.id : null} }"
              >
                <span><i class="fa fa-plus"></i> New Contact</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="pb-2 mb-3">
          <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
            <div class="d-flex">
              <div class="d-flex align-items-center mr-2">
                <label class="pl-3 mr-2 font-weight-bold">Type: </label>
                <multiselect
                  class="filter-select d-inline-block"
                  style="width: 150px;"
                  v-model="selectedContactType"
                  :options="contactTypeList"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder=""
                  label="label"
                  track-by="id"
                  :preselect-first="true"
                  :show-labels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  @input="onChangeFilter"
                ></multiselect>
              </div>
              <div class="d-flex align-items-center mr-2">
                <label class="pl-3 mr-2 font-weight-bold">Tag: </label>
                <multiselect
                  class="filter-select d-inline-block"
                  style="width: 250px;"
                  v-model="selectedTags"
                  :options="tagList"
                  :multiple="true"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder=""
                  label="name"
                  track-by="id"
                  :preselect-first="true"
                  :show-labels="false"
                  :searchable="true"
                  :allowEmpty="true"
                  @input="onChangeFilter"
                ></multiselect>
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex align-items-center mr-2">
                <multiselect
                  class="filter-select d-inline-block"
                  style="width: 150px;"
                  v-model="selectedStatus"
                  :options="statusList"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder=""
                  label="label"
                  track-by="id"
                  :preselect-first="true"
                  :show-labels="false"
                  :searchable="false"
                  :allowEmpty="false"
                  @input="onChangeFilter"
                ></multiselect>
              </div>
              <div class="d-flex align-items-center justify-content-end flex-row mr-2">
                <search-input
                  name="searchInput"
                  v-model="searchFilter"
                  @input="onChangeFilter"
                />
              </div>
              <div class="">
                <button class="btn btn-primary pull-right" @click="onResetFilter()">
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span v-if="!didFetch" class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
          </span>
          <datatable v-else v-bind="tableConfig" class="le-datatable"/>
        </div>
      </div>
    </vuestic-widget>
    <vuestic-modal
      :isOpen="isOpenModalDetail"
      @cancel="onCloseModalDetail"
      :cancelShown="false"
      :okShown="true"
      okText="Edit"
      @ok="onEditButton"
    >
      <span slot="title">Contact <b>#{{ selectedContact.id }}</b></span>
      <div class="row detail-form" v-if="selectedContact">
        <div class="col-md-12">
          <h6 class="mb-3 font-weight-bold">General Information</h6>
          <p><b>Name</b>: {{ selectedContact.full_name }}</p>
          <p><b>Number</b>: {{ selectedContact.number | phone }}</p>
          <p><b>Email</b>: {{ selectedContact.email || 'N/A' }}</p>
          <p><b>Birthday</b>: {{ selectedContact.bday | birthday }}</p>
          <p><b>Anniversary</b>: {{ selectedContact.anniversary | birthday }}</p>
          <p><b>Note</b>: {{ selectedContact.note }}</p>
        </div>
        <div class="col-md-12 mt-4">
          <h6 class="mb-3 font-weight-bold">Network Information</h6>
          <p><b>Ip</b>: {{ selectedContact.ip || 'N/A' }}</p>
          <p><b>Device</b>: {{ selectedContact.device || 'N/A' }}</p>
          <p v-if="selectedContact.location && selectedContact.location.network"><b>Network Organization</b>: {{ selectedContact.location.network.autonomous_system_organization }}</p>
          <p v-if="selectedContact.location && selectedContact.location.security"><b>Vpn</b>: {{ selectedContact.location.security.vpn ? 'True' : 'False' }}</p>
        </div>
        <div v-if="selectedContact.location && selectedContact.location.location" class="col-md-12 mt-4">
          <h6 class="mb-3 font-weight-bold">Location</h6>
          <p><b>City</b>: {{ selectedContact.location.location.city }}</p>
          <p><b>Region</b>: {{ selectedContact.location.location.region }}</p>
          <p><b>Country</b>: {{ selectedContact.location.location.country }}</p>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalTags"
      @cancel="onCloseModalTag"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Tag Contacts</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(onTagContact)">
          <div class="row">
            <div class="col-md-12">
              <tag-selector
                v-model="defaultContactTagsSelected"
              ></tag-selector>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="processing || invalid">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
                <span v-else>Assign</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalDelete"
      @ok="handleDeleteContact"
      @cancel="isOpenModalDelete = false"
      okText="Delete"
      cancelText="Cancel"
      :closeOnOk="false"
      :processing="processing"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">Delete Contact{{isBulkOperation ? 's' : ''}}</span>

      <div v-if="isBulkOperation">Confirm you want to delete <b>{{contactSelectedCount}}</b> selected contacts?</div>
      <div v-else>Confirm you want to delete the contact{{selectedContact.full_name? ' for ' + selectedContact.full_name: ''}}?</div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalAutomationAdd"
      @cancel="onCloseModalAutomation"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Run Automation</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(onAddAutomation)">
          <div class="row">
            <div class="col-md-12 mb-4">
              <label class="input-label has-tool-tip">Automation <a v-b-tooltip.hover title="Run selected automations when contact is imported. FYI, call and VM actions are not processed within this automation." class="fa fa-question-circle tooltip-icon"/></label>
              <multiselect
                :show-labels="false" 
                v-model="selectedAutomation"
                :options="automations"
                :multiple="false"
                :preserve-search="true"
                :allowEmpty="false"
                placeholder="Select Automation"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="automationProcessing || invalid">
                <atom-spinner v-if="automationProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
                <span v-else>Start</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalAutomationDelete"
      @ok="handleDeleteAutomation"
      @cancel="isOpenModalAutomationDelete = false"
      okText="Stop"
      cancelText="Cancel"
      :closeOnOk="false"
      :processing="processing"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">Stop Automation?</span>

      <div>Confirm you want to stop the <b>{{ selectedAutomationProcess ? selectedAutomationProcess.name: 'N/A' }}</b> automation?</div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalSelectAllConfirmation"
      @ok="handleConfirmSelectAll"
      @cancel="isOpenModalSelectAllConfirmation = false"
      okText="Confirm"
      cancelText="Cancel"
      okClass="btn btn-primary"
    >
      <span slot="title" class="text-primary font-weight-bold">Confirm Select All?</span>

      <div>You are going to selected <b>{{ contactTotal }}</b> contacts, Please confirm you want to select all contacts</div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalBlock"
      @ok="handleConfirmBlock"
      @cancel="isOpenModalBlock = false"
      okText="Block"
      cancelText="Cancel"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Block Contacts</span>

      <div>Confirm you want to block <b>{{contactSelectedCount}}</b> selected contacts?</div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isOpenModalOptout"
      @ok="handleConfirmOptout"
      @cancel="isOpenModalOptout = false"
      okText="Optout"
      cancelText="Cancel"
      okClass="btn btn-danger"
    >
      <span slot="title" class="text-danger font-weight-bold">Optout Contacts</span>

      <div>Confirm you want to optout <b>{{contactSelectedCount}}</b> selected contacts?</div>
    </vuestic-modal>

    <vuestic-modal
    :isOpen="showExportModal"
    @ok="handleExportContacts"
    @cancel="showExportModal = false"
    okText="Export"
    cancelText="Close"
    :closeOnOk="false"
    :processing="modalProcessing"
    okClass="btn btn-primary"
    >
      <span slot="title" class="text-primary font-weight-bold">Export Contacts</span>
      <div class="font-weight-bold">Confirm you want to export contacts?</div>
      <div class="row mt-3">
        <div class="col-md-12"><p>Please select custom fields you want to export with.</p></div>
        <div v-for="(item, key) in contactFields" :key="key" class="col-md-12 mb-2 d-flex contact-field-group">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="item.checked">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2 font-weight-bold mt-1" style="cursor: pointer" @click="onCustomFieldClick(item)">{{ item.name }}</span>
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal
      :isOpen="isShowImportModal"
      @ok="handleImportContacts"
      @cancel="isShowImportModal = false"
      okText="Import"
      cancelText="Close"
      :closeOnOk="false"
      :processing="modalProcessing"
      :okDisabled="!importData.attach || !importCheckbox"
      okClass="btn btn-primary"
      :force="true"
    >
      <span slot="title" class="font-weight-bold">Import Contacts</span>

      <div class="row">
        <div class="col-md-12 mt-2">
          <p class="font-weight-bold">
            <span>You can download sample upload file <a class="text-primary" href="javascript:;" @click="onClickDownloadTempalte">here</a>. <br/>You can upload upto 5000 contacts at a time</span>
          </p>
        </div>
        <div class="col-md-12 mb-4">
          <tag-selector
            label="Tags ( Optional )"
            v-model="importData.tags"
          ></tag-selector>
        </div>
        <div class="col-md-12 mb-4">
          <label class="input-label has-tool-tip">Automation <a v-b-tooltip.hover title="Run selected automations when contact is imported. FYI, call and VM actions are not processed within this automation." class="fa fa-question-circle tooltip-icon"/></label>
          <multiselect
            :show-labels="false" 
            v-model="selectedAutomation"
            :options="automations"
            :multiple="false"
            :preserve-search="true"
            :allowEmpty="true"
            placeholder="Select Automation"
            label="name"
            track-by="id"
          >
          </multiselect>
        </div>
        <div class="col-md-12">
          <file-upload
            @upload="onUploadFile"
            @delete="onDeleteFile"
            :show-upload-button="false"
            type="Excel"
            types=".csv, .xlsx"
            :file="importData.attach"
          >
            <span slot="title">EXCEL FILE ( MAX 5k RECORDS )</span>
          </file-upload>
        </div>
        <div class="col-md-12 mb-4 d-flex">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="importCheckbox">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2 font-weight-bold" style="cursor: pointer" @click="importCheckbox = !importCheckbox">I have consent from my customer. The Telephone Consumer Protection Act (TCPA) requires companies to have prior express written consent digitally or electronically from each contact on your subscriber list in order to text the contact.</span>
        </div>
      </div>
    </vuestic-modal>
    <vuestic-modal
    :isOpen="showTemplateModal"
    @ok="handleDownloadTemplate"
    @cancel="showTemplateModal = false"
    okText="Download"
    cancelText="Close"
    :closeOnOk="false"
    :processing="modalProcessing"
    okClass="btn btn-primary"
    >
      <span slot="title" class="text-primary font-weight-bold">Download Template File</span>
      <div class="row mt-3">
        <div class="col-md-12"><p>Please select custom fields you want to import with.</p></div>
        <div v-for="(item, key) in contactFields" :key="key" class="col-md-12 mb-2 d-flex contact-field-group">
          <div class="custom-checkbox-wrapper d-inline-block">
            <label class="checkbox-label">
              <input type="checkbox" v-model="item.checked">
              <span class="checkbox-custom"></span>
            </label>
          </div>
          <span class="d-inline-block ml-2 font-weight-bold mt-1" style="cursor: pointer" @click="onCustomFieldClick(item)">{{ item.name }}</span>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import components from '../../common/tables/comps'
import voca from 'voca'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import SearchInput from '../../common/SearchInput'
import TagSelector from '../../common/TagSelector'
import FileUpload from "../../common/FileUpload";
import { contactTypes } from '../../../constants'
import AppDropdownSelect from '../../common/app-dropdown-select/AppDropdownSelect';
import SMSLength from '../../common/SMSLength'
import cloneDeep from "clone-deep";

export default {
  components: {
    SearchInput,
    TagSelector,
    AppDropdownSelect,
    SMSLength,
    FileUpload
  },

  data () {
    return {
      bulkActionOptions: [
        { icon: 'fa fa-check', label: 'Select All', value: 'selectAll' },
        { icon: 'fa fa-tag', label: 'Apply Tags', value: 'tags' },
        { icon: 'fa fa-cog', label: 'Run Automation', value: 'automation'  },
        { icon: 'fa fa-sign-out', label: 'Opt-Out', value: 'optout'  },
        { icon: 'fa fa-times', label: 'Block', value: 'block'  },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete'  },
      ],
      automations: [],
      selectedAutomation: null,
      automationProcessing: false,
      selectedInstall: null,
      contactTypeList: [], 
      selectedContactType: null,
      isOpenModalDetail: false,
      searchFilter: '',
      selectedContact: {},
      isOpenModalAutomationAdd: false,
      isOpenModalAutomationDelete: false,
      selectedAutomationProcess: null,
      isOpenModalTags: false,
      isOpenModalDelete: false,
      isOpenModalSelectAllConfirmation: false,
      isOpenModalOptout: false,
      isOpenModalBlock: false,
      newContactDefault: {
        first_name: '',
        last_name: '',
        number: '',
        email: '',
        bday: '',
        anniversary: '',
        note: '',
        tags: [],
      },
      defaultContactTagsSelected: [],
      tagsSelected: [],
      isConfirmed: false,
      isBulkOperation: false,
      tagList: [],
      selectedTags: [],
      statusList: [],
      selectedStatus: null,
      importCheckbox: false,
      isSelectAll: false,
      contactSelectedCount: 0,
      tableConfig: {
        supportBackup: false,
        supportNested: true,
        HeaderSettings: false,
        tblClass: "table-bordered contact-table",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "Number", field: 'number', thClass:"left", tdClass: "left", tdStyle: {fontStyle: "normal"}, tdComp: "TdPhone" },
            { title: "Name", field: "full_name", tdStyle: {fontStyle: "normal"}, thClass:"left", tdClass: "left" },
            { title: "Email", field: "email", tdStyle: {fontStyle: "normal"}, thClass:"left", tdClass: "left" },
            { title: "Department", field: "fromDepartment.name", tdStyle: {fontStyle: "normal"}, thClass:"left", tdClass: "left", tdComp: "TdNestedValue" },
            { title: "Type", field: "type", tdStyle: {fontStyle: "normal"}, tdClass: "center", tdComp: "TdContactType" },
            { title: "Tags", field: "tags", tdComp: 'TdTagsCount', tdStyle: {fontStyle: "normal"}, tdClass: "center" },
            { title: "Opt In", sortable: true, field: "created_at", tdStyle: {fontStyle: "normal"}, thClass:"left", tdClass: "left", tdComp: "TdDay" },
            { title: "Opt Out", sortable: true, field: "opt_out", tdStyle: {fontStyle: "normal"}, thClass:"left", tdClass: "left", tdComp: "TdDay" },
            { title: '', tdComp: 'ContactMore', visible: 'true', tdClass: 'center'},
          ];
        })(),
        data: [],
        selection: [],
        total: 0,
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      isShowImportModal: false,
      importData: {
        tags: [],
        attach: null,
      },
      modalProcessing: false,
      showExportModal: false,
      showTemplateModal: false,
    }
  },

  created() {
    if (this.$route.query.tag) {
      this.selectedTags = [{
        id: this.$route.query.tag
      }]
    }
    
    this.tableConfig.xprops.eventbus.$on('onOptContact', row => {
      this.toggleOptOut(row)
    })

    this.tableConfig.xprops.eventbus.$on('onDetailContact', row => {
      this.onModalDetail(row)
    })

    this.tableConfig.xprops.eventbus.$on('onEditContact', row => {
      this.$router.push({ name: 'business.contacts.edit', params: { id: row.id } })
    })
    
    this.tableConfig.xprops.eventbus.$on('onSendMessage', row => {
      this.onSendMessage(row)
    })

    this.tableConfig.xprops.eventbus.$on('onDeleteContact', row => {
      this.onModalDelete(row)
    })

    this.tableConfig.xprops.eventbus.$on('onTagLinkClick', row => {
      this.onModalTag(row)
    })

    this.contactTypeList = contactTypes;
    const offset = (this.page - 1 >= 0 ? this.page - 1 : 0) * this.perPage;
    this.tableConfig.query = {
      offset,
      limit: this.perPage,
      sort: 'created_at',
      order: 'desc'
    }
    this.loadTags();
    this.initFilter();
  },

  mounted () {
    this.tableConfig = {
      ...this.tableConfig,
      data: this.contactData,
      total: this.contactTotal,
    }
    this.selectedInstall = this.selectedInstallItem;
    this.selectedContactType = contactTypes.find(item => item.id === this.filter.type);
    this.searchFilter = this.filter.keyword;

    if(!this.installDidFetch)
    {
      this.loadInstall();
    }
    this.loadAutomations();
  },

  watch: {
    selectedInstallItem: {
      handler: function(newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.selectedInstall = newValue;
          this.initTable();
        }
      },
      deep: true,
    },
    contactData: {
      handler: function(newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    contactTotal: {
      handler: function(newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    filter: {
      handler: function(newValue, oldValue) {
        if (newValue != oldValue)
        {
          this.initTable();
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler: function(newValue, oldValue) {
        this.loadContacts()
      },
      deep: true,
    },
    'tableConfig.selection': {
      handler: function(newValue, oldValue) {
        if (this.isSelectAll)
        {
          this.contactSelectedCount = this.contactTotal
          this.isSelectAll = false;
        } else {
          this.contactSelectedCount = newValue.length
        }
      },
      deep: true,
    }
  },

  methods: {
    loadAutomations() {
      this.$store.dispatch('automation/all')
        .then((res) => {
          this.automations = res.filter(item => item.active)
        })
        .catch(() => {
        })
    },
    onClickDownloadTempalte() {
      this.contactFields.forEach(item => {
        item.checked = false
      })
      
      this.showTemplateModal = true
    },

    handleDownloadTemplate() {
      const tmp =  this.contactFields.filter((item) => {
        return item.checked
      })
      const fieldIds = [];
      tmp.forEach((item) => {
        fieldIds.push(item.id)
      })
      const param = {
        contactFields: fieldIds
      }
      this.modalProcessing = true
      this.$store.dispatch('contact/templateDownload', param)
        .then((res) => {
          this.modalProcessing = false
          this.showTemplateModal = false
          const blob = new Blob([res.data], { type: 'application/xlsx' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'template.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(() => {
          this.modalProcessing = false
        })
    },

    onClickImportContact() {
      this.importData = {
        tags: [],
        attach: null,
      }
      this.isShowImportModal = true;
      this.importCheckbox = false;
      this.selectedAutomation = null;
    },
    onUploadFile(event) {
      if (event.file) {
        this.importData.attach = event.file
      }
    },
    onDeleteFile(event) {
      this.importData.attach = null
    },

    handleImportContacts() {
      if (!this.importData.attach) return;

      this.modalProcessing = true

      let importForm = new FormData()
      for (let tag of this.importData.tags) {
        importForm.append('tags[]', tag.id)
      }
      importForm.append('attach', this.importData.attach)
      importForm.append('installId', this.installSelectedId)
      if (this.selectedAutomation)
      {
        importForm.append('automationId', this.selectedAutomation.id)
      }

      this.$store.dispatch('contact/import', importForm)
        .then(() => {
          this.modalProcessing = false
          this.isShowImportModal = false
          this.loadContacts()
        })
        .catch(() => {
          this.modalProcessing = false
        })
    },

    onClickExport() {
      this.contactFields.forEach(item => {
        item.checked = false
      })

      this.$nextTick(() => {
        this.showExportModal = true
      })
    },

    onCustomFieldClick(item) {
      item.checked = !item.checked
    },

    handleExportContacts() {
      if (this.installSelectedId) {
        const { query } = this.tableConfig;
        const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
        const tmp =  this.contactFields.filter((item) => {
          return item.checked
        })
        const fieldIds = [];
        tmp.forEach((item) => {
          fieldIds.push(item.id)
        })
        const param = {
          installID: this.installSelectedId,
          queries: {
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
            page,
            per_page: query.limit,
            order: query.order,
            sort: query.sort,
            contactFields: fieldIds
          }
        }
        this.modalProcessing = true
        this.$store.dispatch('contact/export', param)
          .then((res) => {
            this.modalProcessing = false
            this.showExportModal = false
            const blob = new Blob([res.data], { type: 'application/xlsx' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'contacts.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch(() => {
            this.modalProcessing = false
          })
      }
    },

    onResetFilter() {
      this.selectedTags = [this.tagList[0]]
      this.selectedStatus = this.statusList[1]
      this.selectedContactType = this.contactTypeList[0]
      this.searchFilter = null
      this.onChangeFilter()
    },

    initFilter() {
      this.statusList = [
        { id: 0, label: 'All' },
        { id: 1, label: 'OptIn' },
        { id: 2, label: 'OptOut' },
        { id: 3, label: 'Blocked' },
      ]
      this.selectedStatus = this.statusList[1]
    },
    loadTags() {
      this.$store
        .dispatch('tag/all')
        .then((res) => {
          let result = res
          result.unshift({
            id: 0,
            name: 'All'
          })
          this.tagList = result
          if (this.$route.query.tag) {
            this.selectedTags = this.tagList.filter(item => item.id == this.$route.query.tag)
          }
          if (!this.selectedTags || !this.selectedTags.length) {
            this.selectedTags = [this.tagList[0]]
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    
    onAddAutomation() {
      if (!this.selectedAutomation)
      {
        Vue.$toast.open({
          message: 'Please select automation to start.',
          type: 'error',
        })
        return false
      }

      let type = 'bulk'
      let filter = {}

      if (this.contactSelectedCount == this.contactTotal)
      {
        type = 'all';
        filter = {
          installID: this.installSelectedId,
          type: this.selectedContactType.id,
          search: this.searchFilter,
          tags: this.selectedTags.map(t => t.id),
          status: this.selectedStatus ? this.selectedStatus.id: 1,
        }
      }

      const params = {
        automationId: this.selectedAutomation.id,
        param: {
          type,
          contactIds: this.tableConfig.selection.map(item => item.id),
          filter,
        }
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/bulkAutomation', params)
        .then((res) => {
          this.tableConfig.selection = []
          this.automationProcessing = false
          this.isOpenModalAutomationAdd = false
          this.contactSelectedCount = 0
          this.isSelectAll = false
        })
        .catch(() => {
          this.automationProcessing = false
          this.contactSelectedCount = 0
          this.isSelectAll = false
        })
    },
    
    onCloseModalAutomation() {
      this.isOpenModalAutomationAdd = false;
    },

    onAddAutomationClick() {
      this.selectedAutomation = null;
      this.isOpenModalAutomationAdd = true;
    },

    onOptoutClick() {
      this.isOpenModalOptout = true;
    },

    onBlockClick() {
      this.isOpenModalBlock = true;
    },

    handleConfirmOptout() {
      let ids = [];
      let type = 'single';
      let filter = {}
      if (this.isBulkOperation)
      {
        ids = this.tableConfig.selection.map(item => item.id);
        type = 'bulk';
        if (this.contactSelectedCount == this.contactTotal)
        {
          type = 'all';
          filter = {
            installID: this.installSelectedId,
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
          }
        }
      } else {
      }

      const payload = {
        ids: ids,
        type: type,
        filter,
      }
      
      this.$store.dispatch('contact/bulkOptout', payload).then(() => {
        this.isOpenModalOptout = false;
        this.isBulkOperation = false;
        this.loadContacts();
      })
    },

    handleConfirmBlock() {
      let ids = [];
      let type = 'single';
      let filter = {}
      if (this.isBulkOperation)
      {
        ids = this.tableConfig.selection.map(item => item.id);
        type = 'bulk';
        if (this.contactSelectedCount == this.contactTotal)
        {
          type = 'all';
          filter = {
            installID: this.installSelectedId,
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
          }
        }
      } else {
      }

      const payload = {
        ids: ids,
        type: type,
        filter,
      }
      
      this.$store.dispatch('contact/bulkBlock', payload).then(() => {
        this.isOpenModalBlock = false;
        this.isBulkOperation = false;
        this.loadContacts();
      })
    },
    
    onDeleteAutomation(item) 
    {
      this.isOpenModalAutomationDelete = true;
      this.selectedAutomationProcess = item;
    },

    handleDeleteAutomation()
    {
      this.isOpenModalAutomationDelete = false;

      const params = {
        contactId: this.selectedContact.id, 
        processId: this.selectedAutomationProcess.id,
      }

      this.automationProcessing = true

      this.$store
        .dispatch('contact/stopAutomation', params)
        .then(() => {
          this.automationProcessing = false
        })
        .catch(() => {
        })
    },

    onSendMessage(contact) {
      this.$store
        .dispatch('contact/setOnTop', contact.id)
        .then(() => {
          this.$router.push({ name: 'messaging', query: {installId: contact.install_id} });
        })
        .catch(() => {
        })
    },
    
    toggleOptOut(contact) {
      this.$store
        .dispatch('contact/toggleOptOut', contact.id)
        .then(() => {
          this.loadContacts();
        })
        .catch(() => {
        })
    },

    formatPhone(val) {
      if (!val)
        return '';
      if (!val.startsWith('+'))
        val = '+' + val;

      const phoneNumber = parsePhoneNumberFromString(val)
      return phoneNumber.formatNational()
    },

    onRefresh() {
      this.loadContacts()
    },

    onModalTag(row) {
      this.defaultContactTagsSelected = row.tags
      this.selectedContact = row
      this.isBulkOperation = false
      this.isOpenModalTags = true
    },

    onTagContact() {
      let ids = [];
      let type = 'single';
      let filter = {}
      if (this.isBulkOperation)
      {
        ids = this.tableConfig.selection.map(item => item.id);
        type = 'bulk';
        if (this.contactSelectedCount == this.contactTotal)
        {
          type = 'all';
          filter = {
            installID: this.installSelectedId,
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
          }
        }
      } else {
        ids = [this.selectedContact.id]
      }
      const payload = {
        ids: ids,
        tags: this.defaultContactTagsSelected,
        type: type,
        filter,
      }
      
      this.$store.dispatch('contact/bulkTags', payload).then(() => {
        this.isOpenModalTags = false;
        this.defaultContactTagsSelected = []
      })
    },

    onCloseModalTag() {
      this.isOpenModalTags = false
      this.defaultContactTagsSelected = [];
    },

    onBulkActionClick(item) {
      if (item.value === 'selectAll')
      {
        if (this.contactTotal)
        {
          this.isOpenModalSelectAllConfirmation = true
        }
        return false;
      }
      
      if (!this.tableConfig.selection.length)
        return false

      this.isBulkOperation = true;

      switch (item.value) {
        case 'tags':
          this.isOpenModalTags = true
          break;
        case 'automation':
          this.onAddAutomationClick()
          break;
        case 'optout':
          this.onOptoutClick()
          break;
        case 'block':
          this.onBlockClick()
          break;
        case 'delete':
          this.isOpenModalDelete = true;    
        default:
          break;
      }
    },

    onEditButton() {
      this.onCloseModalDetail()
      this.$router.push({ name: 'business.contacts.edit', params: { id: this.selectedContact.id } })
    },

    onCloseModalDetail() {
      this.isOpenModalDetail = false
    },
    
    onModalDelete(contact) {
      this.isBulkOperation = false;
      this.selectedContact = contact;
      this.isOpenModalDelete = true;
    },

    onModalDetail(contact) {
      this.selectedContact = contact;
      this.isOpenModalDetail = true;
    },

    handleConfirmSelectAll() {
      this.isSelectAll = true;
      this.tableConfig.selection = [...this.contactData]
      this.isOpenModalSelectAllConfirmation = false
    },

    handleDeleteContact() {
      if (this.isBulkOperation)
      {
        let type = 'bulk';
        let filter = {};
        if (this.contactSelectedCount == this.contactTotal)
        {
          type = 'all';
          filter = {
            installID: this.installSelectedId,
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
          }
        }
        const param = {
          type,
          ids: this.tableConfig.selection.map(item => item.id),
          filter,
        }
        this.$store.dispatch('contact/bulkDelete', param).then((res) => {
          this.isOpenModalDelete = false;
          this.isBulkOperation = false;
          this.loadContacts();
        });
      } else {
        this.$store.dispatch('contact/delete', this.selectedContact.id).then((res) => {
          this.isOpenModalDelete = false;
          this.isBulkOperation = false;
        });
      }
    },

    initTable() {
      this.tableConfig = {
        ...this.tableConfig,
        data: [],
        total: 0,
        query: {
          ...this.tableConfig.query,
          offset: 0,
          limit: this.perPage
        }
      }
    },

    onChangeFilter() {
      const payload = {
        type: this.selectedContactType.id,
        keyword: this.searchFilter,
        tags: this.selectedTags.map(t => t.id),
        status: this.selectedStatus ? this.selectedStatus.id: 1,
      }
      this.$store.commit('contact/updateFilter', payload)
    },

    loadInstall () {
      this.$store.dispatch('install/fetchAllInstalls').then((res) => {
        const { data } = res;
        if (!this.installSelectedId && data.length)
        {
          this.$store.commit('install/selectInstall', data[0]['id'])
        }
      });
    },

    loadContacts() {
      this.tableConfig.selection = []

      this.contactSelectedCount = 0
      this.isSelectAll = false
      if (this.installSelectedId) {
        const { query } = this.tableConfig;
        const page = query.offset ? Math.floor(query.offset /  query.limit) + 1 : 1;
        const param = {
          installID: this.installSelectedId,
          query: {
            type: this.selectedContactType.id,
            search: this.searchFilter,
            tags: this.selectedTags.map(t => t.id),
            status: this.selectedStatus ? this.selectedStatus.id: 1,
            page,
            per_page: query.limit,
            order: query.order,
            sort: query.sort
          }
        }

        this.$store.dispatch('contact/getList', param)
      }
    },

    onChangeInstall(item) {
      this.$store.commit('install/selectInstall', item.id)
    },

    getFullName(item) {
      return item.firstname + ' ' + item.lastname;
    },

    ...mapMutations('install', {
      selectInstall: 'selectInstall'
    }),

    ...mapMutations('contact', {
      updateFilter: 'updateFilter'
    })
  },
  computed: {
    installNumber() {
      if (this.selectedInstall)
        return this.selectedInstall.number
      return ''
    },
    installs() {
      return this.$store.getters['install/tableData']('active');
    },
    selectedInstallItem() {
      return this.optionInstalls && this.optionInstalls.find(item => item.id === this.installSelectedId);
    },
    optionInstalls() {
      return this.installs.map(item => {
        return {
          id: item.id,
          number: item.available_number,
          label: this.formatPhone(item.available_number) + ' : ' + item.website,
        }
      })
    },
    loading() {
      return this.installFetching || this.fetching;
    },
    contactFields() {
      const res = cloneDeep(this.$store.getters["auth/contactFields"]);
      res.forEach(item => {
        item.checked = false
      })
      return res
    },
    ...mapState('install', {
      installFetching: 'fetching',
      installDidFetch: 'didFetch',
      installSelectedId: 'selectedId',
    }),
    ...mapState('contact', {
      fetching: 'fetching',
      didFetch: 'didFetch',
      processing: 'processing',
      page: 'page',
      perPage: 'perPage',
      filter: 'filter',
      contactData: 'data',
      contactTotal: 'total'
    })
  }
};
</script>

<style lang="scss">
  .contact-page {
    .loader {
      padding: 0;
    }
    .header-row {
      label {
        font-size: 1rem;
      }
    }
    .install-select {
      input {
        border: none;
      }
    }
    .filter-select {
      .multiselect__single {
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .table-wrapper {
      .atom-spinner-wrapper{ 
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 200px;
        .atom-spinner {
          display: inline-block;
        }
      }
    }
    .filter-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .right-filter-wrapper {
      @media (max-width: 1230px) {
        flex-direction: column!important;
        align-items: flex-end!important;
        > div {
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
    .detail-form {
      p {
        margin-bottom: 3px;
        margin-left: 2em;
      }
    }
  }
  .contact-field-group {
    .checkbox-label {
      --primaryColor: #3578c6;
    }
    .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
      border-color: $text-gray;
      top: 4px;
      height: 17px;
      width: 17px;
    }

    .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::before {
      width: unset;
      height: unset;
    }
  
    .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
      left: 5px;
      top: 0px;
    }
  }
</style>

<style scoped lang="scss">
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
  .tag-wrapper {
    span.add-tag-btn {
      display: inline-block;
      border: 1px dashed #ccc;
      border-radius: 5px;
      padding: 2px 5px;
      margin-bottom: 5px;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        border-color: #3578c6;
        color: #3578c6;
      }
    }
    span.tag {
      cursor: pointer;
      background-color: #3578c6;
      color: #fff;
      border-radius: 5px;
      padding: 3px 5px;
      font-size: 0.85em;
      display: block;
      margin-right: 5px;
      display: inline-block;
      margin-bottom: 5px;
      i {
        background-color: #325f94;
        padding: 2px 3px;
        border-radius: 50%;
      }
      &:hover i {
        background-color: #15457d;
      }
    }
  }
</style>